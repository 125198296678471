<template>
  <div>
    <div>
      <div class="row">
        <div class="col-12">
          <div class="card card-custom gutter-b example example-compact">
            <div
              class="
                card-header
                d-flex
                align-items-center
                justify-content-between
              "
            >
              <div class="card-title">
                <h3 class="card-label">
                  {{ $t('MENU.ENTERPRICE.PROVIDERS.CONTRACTS') }}
                </h3>
              </div>
              <div class="card-button">
                <v-btn
                  small
                  to="/addprovidercontract"
                  class="ml-2"
                  color="primary"
                  >{{ $t('MENU.ENTERPRICE.PROVIDERS.CONTRACTS') }} +</v-btn
                >
              </div>
            </div>
            <div class="card-body">
              <v-data-table
                :headers="headers"
                hide-default-footer
                :items="getAllCorCards"
                loading-text="Malumotlar yuklanmoqda..."
                no-data-text="Malumotlar topilmadi!"
                :loading="isLoading"
                single-select
                @click:row="rowClick"
                item-key="id"
                class="row-pointer"
              >
                <template late v-slot:[`item.number_16_digit`]="{ item }">
                  <!-- {{ item.remain }} -->
                  <!-- <input type="text" disabled v-model="item.remain" v-money="money" /> -->
                  <input
                    style="background: transparent; border: none"
                    v-model="item.number_16_digit"
                    type="text"
                    disabled
                  />
                </template>
                <template v-slot:[`item.remain`]="{ item }">
                  <!-- {{ item.remain }} -->
                  <!-- <input type="text" disabled v-model="item.remain" v-money="money" /> -->
                  <input
                    style="background: transparent; border: none"
                    v-model="item.remain"
                    type="text"
                    disabled
                  />
                </template>
                <template v-slot:[`item.index`]="{ index }">
                  {{ index + 1 }}
                </template>
                <!-- start data -->
                <template v-slot:[`item.start_date`]="{ item }">
                  {{ item.start_date | formatDate }}
                </template>

                <!-- end data -->
                <template v-slot:[`item.end_date`]="{ item }">
                  {{ item.end_date | formatDate }}
                </template>
                <template v-slot:[`item.amount`]="{ item }">
                  <span v-if="item.amount !== null">
                    {{ item.amount.toLocaleString('zh-ZH') }}
                  </span>
                </template>

                <template v-slot:[`item.is_active`]="{ item }">
                  <div v-if="item.is_active">
                    <span class="label label-primary label-dot"></span>&nbsp;
                    <span class="font-weight-bold text-primary">Faol</span>
                  </div>
                  <div v-else>
                    <span class="label label-danger label-dot"></span>&nbsp;
                    <span class="font-weight-bold text-danger">Faol emas</span>
                  </div>
                </template>
                <template
                  v-slot:[`item.contract_type`]="{ item }"
                  class="text-info"
                >
                  <b-alert
                    show
                    pill
                    :variant="item.contract_class_name"
                    class="p-0 text-center mb-0"
                    >{{ item.contract_type }}</b-alert
                  >
                </template>
                <template v-slot:[`item.action`]="{ item }">
                  <action :index="item.id"></action>
                </template>
              </v-data-table>
              <div class="text-left py-5">
                <v-pagination
                  v-model="currentPage"
                  :total-visible="10"
                  @input="getPostData(currentPage)"
                  :length="Math.ceil(allProvidersContract.count / 10)"
                ></v-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import action from './action'
export default {
  data() {
    return {
      counter: 0, // count the clicks
      timer: null, // Needs to be specified here so it can be accessed on both clicks
      selectedId: -1,
      currentPage: 1,
      index: ''
    }
  },
  components: {
    action
  },
  created() {
    if (this.$route.query.page !== undefined) {
      this.getPostData(this.$route.query.page)
      this.currentPage = this.toInt(this.$route.query.page)
    } else {
      this.getPostData(this.currentPage)
    }
  },
  computed: {
    headers() {
      return [
        { text: 'ID', value: 'id' },
        { text: this.$t('TABLE_HEADER.PROVIDER'), value: 'provider_name' },
        { text: "Boshlang'ich sanasi", value: 'start_date' },
        { text: 'Tugash sanasi', value: 'end_date' },
        { text: this.$t('TABLE_HEADER.DATE'), value: 'provider_inn' },
        { text: this.$t('TABLE_HEADER.AMOUNT'), value: 'amount' },
        { text: this.$t('TABLE_HEADER.STATUS'), value: 'is_active' },
        { text: this.$t('TABLE_HEADER.CONTRACT_TYPE'), value: 'contract_type' },
        { text: null, value: 'action', sortable: false }
      ]
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    getAllCorCards() {
      return this.$store.state.requests.allProvidersContract.results
    },
    allProvidersContract() {
      return this.$store.state.requests.allProvidersContract
    }
  },
  // SET_BREADCRUMB
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t('MENU.ENTERPRICE.TITLE') },
      { title: this.$t('MENU.ENTERPRICE.PROVIDERS.TITLE') },

      { title: this.$t('MENU.ENTERPRICE.PROVIDERS.CONTRACTS') }
    ])
  },
  methods: {
    rowClick: function (item, row) {
      var self = this
      this.counter++
      if (this.counter == 1) {
        this.timer = setTimeout(function () {
          self.counter = 0
          row.select(true)
          this.selectedId = item.id
        }, 200)
      } else {
        clearTimeout(this.timer)
        self.counter = 0
        this.$router.push('/providercontractdetail/' + item.id + '/')
        row.select(false)
      }
    },
    getPostData(value) {
      if (
        this.toInt(this.$router.currentRoute.query.page) !== this.toInt(value)
      ) {
        this.$router.push({
          path: this.$router.currentRoute.path,
          query: { page: value }
        })
      }
      this.$store.dispatch('getAllProvidersContract', value)
    },
    toInt(val) {
      if (typeof val == 'string') {
        return parseInt(val, 10)
      } else return val
    }
  }
}
// Style
</script>

<style scoped>
.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  /* font-size: 0.9rem !important; */
  color: #000 !important;
  font-weight: 500 !important;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  /* font-size: 0.9rem !important; */
  color: #000 !important;
  height: 60px !important;
}
.v-data-table__wrapper {
  overflow-x: initial !important;
  overflow-y: initial !important;
}

.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}
.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.077) !important;
}
</style>
