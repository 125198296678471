<template>
  <b-dropdown
    size="sm"
    variant="link"
    toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
    no-caret
    right
    no-flip
  >
    <template v-slot:button-content>
      <i class="ki ki-bold-more-ver"></i>
    </template>
    <!--begin::Navigation-->
    <div class="navi navi-hover" style="width: 250px">
      <!-- <div  v-for="item in index" :key="item">{{item.id}} </div> -->

      <!-- {{index}} -->
      <b-dropdown-text tag="div" class="navi-item">
        <router-link :to="'/providercontractdetail/' + index" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon-file-1 text-success"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.DETAILS') }}</span>
        </router-link>
        <router-link :to="'/providercontractedit/' + index" class="navi-link">
          <span class="navi-icon">
            <i class="flaticon-edit-1 text-warning"></i>
          </span>
          <span class="navi-text">{{ $t('ACTION.UPDATE') }}</span>
        </router-link>
        <!-- <router-link :to="'/dopprovidercontract/' + index" class="navi-link">
          <span class="navi-icon ">
            <i class="flaticon-edit-1 text-warning"></i>
          </span>
          <span class="navi-text">Q'shimcha shartnoma</span>
        </router-link>
        <v-btn
          color="red"
          dark
          style="width:100%; margin:10px 0;"
          small
          @click="snackbar = true"
        >
          <span class="navi-icon mr-2">
            <i class="flaticon-delete-1 text-white ml-auto"></i> </span
          >Kartani O’chirish
        </v-btn>
        <v-snackbar color="red" :centered="true" :top="true" v-model="snackbar">
          Shartnomani o'chirish
          <template v-slot:action="{ attrs }">
            <v-btn text v-bind="attrs" @click="contractDelete(index)">
              Tasdiqlash
            </v-btn>
          </template>
        </v-snackbar> -->
      </b-dropdown-text>
    </div>
    <!--end::Navigation-->
  </b-dropdown>
</template>

<script>
// import axios from "axios";
export default {
  data() {
    return {
      snackbar: false,
      text: "Hello, I'm a snackbar"
    }
  },
  name: 'DriverAction',
  props: {
    allUsers: Array,
    btnClass: String,
    index: Number,
    id: Number
  },

  methods: {
    contractDelete(value) {
      this.snackbar = false
      this.$store.dispatch('deleteProviderContract', value)
    }
  },
  components: {},
  mounted() {}
}
</script>
<style lang="scss" scoped>
.v-application ul,
.v-application ol {
  padding: 0 !important;
}
.dropdown-menu {
  z-index: 80;
}
.navi .navi-item .navi-link {
  padding: 0.75rem 1rem;
}
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>
